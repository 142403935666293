import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function StatTiles({ color, count, text }) {
  return (
    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
      <div
        className={`card card-custom bg-light-${color} gutter-b `}
        style={{
          height: "150px"
        }}
      >
        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-${color} ml-n2`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div
            className={`card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block`}
          >
            {count}
          </div>
          <span className={` font-weight-bold text-muted font-size-lg mt-1`}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
}

export default StatTiles;
