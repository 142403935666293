import { createSlice } from '@reduxjs/toolkit'

const initialSolutionsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	solutionForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const solutionsSlice = createSlice({
	name: 'solutions',
	initialState: initialSolutionsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getSolutionById
		solutionFetched: (state, action) => {
			state.actionsLoading = false
			state.solutionForEdit = action.payload.solutionForEdit
			state.error = null
		},
		// findSolutions
		solutionsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createSolution
		solutionCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateSolution
		solutionUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.solution.id) {
					return action.payload.solution
				}
				return entity
			})
		},
		// deleteSolution
		solutionDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteSolutions
		solutionsDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// solutionsUpdateState
		solutionsStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
		// solutionUpdateState
		solutionStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { status } = action.payload
			state.solutionForEdit.status = status
		},
	},
})
