import { createSlice } from "@reduxjs/toolkit";

const initialLeadState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  leadForEdit: undefined,
  lastError: null,
  emailPayload: {}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const leadSlice = createSlice({
  name: "lead",
  initialState: initialLeadState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getLeadById
    leadFetched: (state, action) => {
      state.actionsLoading = false;
      state.leadForEdit = action.payload.leadForEdit;
      state.error = null;
    },
    setEmailPayload: (state, action) => {
      state.actionsLoading = false;
      state.emailPayload = action.payload.payload;
      state.error = null;
    },
    // findLead
    leadsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dashboardFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard = action.payload.dashboard;
    },
    // dashboardFetched
    // createLead
    leadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      let _upsertedLead = state.entities.find(
        ele => ele.id === action.payload.lead.id
      );

      if (_upsertedLead) {
        state.entities = state.entities.map(entity => {
          if (entity.id === _upsertedLead.id) {
            return _upsertedLead;
          }
          return entity;
        });
      } else {
        state.entities.push(action.payload.lead);
      }
    },
    // updateLead
    leadUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.lead.id) {
          return action.payload.lead;
        }
        return entity;
      });
    },
    // deleteLead
    leadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    leadConverted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    leadsConverted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteLead
    leadsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // leadUpdateState
    leadStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    leadEnrollStateUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.leadForEdit.PreferenceCenters = state.leadForEdit.PreferenceCenters.map(
        record => {
          if (record.LeadPreference.id === action.payload.id) {
            record.LeadPreference.enrolled = !record.LeadPreference.enrolled;
          }
          return record;
        }
      );
    },
    leadStatusStateUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.leadForEdit.PreferenceCenters = state.leadForEdit.PreferenceCenters.map(
        record => {
          if (record.LeadPreference.id === action.payload.id) {
            record.LeadPreference.isActive = !record.LeadPreference.isActive;
          }
          return record;
        }
      );
    },
    leadEmailSent: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
