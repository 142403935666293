import { createSlice } from "@reduxjs/toolkit";

const initialPreferenceState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  preferenceForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const preferenceSlice = createSlice({
  name: "preference",
  initialState: initialPreferenceState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPreferenceById
    preferenceFetched: (state, action) => {
      state.actionsLoading = false;
      state.preferenceForEdit = action.payload.preferenceForEdit;
      state.error = null;
    },
    // findPreference
    preferencesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      // modify payload to include parent preference name
      entities.forEach(pc => {
        let ParentPCObj = [];
        if (pc.parentPC != null) {
          ParentPCObj = entities.filter(
            preference => pc.parentPC === preference.id
          );
        } else {
          ParentPCObj = [];
        }
        pc.parentPCName = ParentPCObj.length ? ParentPCObj[0].name : "";
      });
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dashboardFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard = action.payload.dashboard;
    },
    // dashboardFetched
    // createPreference
    preferenceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      let currentPC = action.payload.preference;
      let ParentPCObj = [];
      if (currentPC.parentPC != null) {
        ParentPCObj = state.entities.filter(
          preference => currentPC.parentPC === preference.id
        );
      } else {
        ParentPCObj = [];
      }
      currentPC.parentPCName = ParentPCObj.length ? ParentPCObj[0].name : "";
      state.entities.push(currentPC);
    },
    // updatePreference
    preferenceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.preference.id) {
          return action.payload.preference;
        }
        return entity;
      });
    },
    // deletePreference
    preferenceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletePreference
    preferencesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // preferenceUpdateState
    preferenceStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    preferenceEmailSent: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
