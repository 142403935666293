import React from 'react'

export function Switch({ field, ...props }) {
	return (
		<>
			<span className='text-dark-75 font-weight-bolder d-block font-size-sm'>
				<label>{props.label}</label>
				<span className='switch switch-sm'>
					<label>
						<input
							type='checkbox'
							checked={field.value}
							{...field}
							{...props}
						/>
						<span></span>
					</label>
				</span>
			</span>
		</>
	)
}
