import React, { useEffect, useState } from "react";
import "./pages/managePage.css";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { Switch } from "react-router-dom";
import queryString from "query-string";
// import axios from "axios";

function ManagePage() {
  const [responseValue, setResponseValue] = useState("");
  const [queryData, setQueryData] = useState({});
  let { prefCode } = useParams();

  useEffect(() => {
    setQueryData(queryString.parse(window.location.search));
  }, [prefCode]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!responseValue || responseValue === "") {
      return toast.error("Please select an option");
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/preferences/${prefCode}`,
      { payload: { ...queryData, responseValue } }
    );

    if (response.data.status) {
      // redirect to thank you page
      return (window.location.href = "/manage/pref/success");
    } else {
      toast.error("Something went wrong!");
    }
  };
  return (
    <>
      <ToastContainer />
      <div
        className="main-container"
        style={{
          backgroundColor: "#232323",
          color: "white",
          borderTop: "0px",
          paddingTop: "5px",
          paddingBottom: "30px"
        }}
      >
        <div className="content">
          <br />
          <span
            style={{
              color: "#f5f5f5",
              fontFamily: "Noto Sans, Arial,sans-serif",
              fontSize: "28px",
              fontWeight: "500",
              lineHeight: "38px"
            }}
          >
            Update Your Email Preferences
          </span>
          <br />
          <br />
          <span
            style={{
              color: "#ffffff",
              fontFamily: "Noto Sans, Arial,sans-serif",
              fontSize: "14px"
            }}
          >
            Email Address: <span id="current-email">{queryData?.email}</span>
          </span>
          &nbsp;
          <br />
          <img
            alt="EPIX NOW"
            src="https://appboy-images.com/appboy/communication/assets/image_assets/images/5c8913ba733b1606ca3aea2b/original.png?1552487354"
            style={{
              width: "111px",
              height: "4px"
              // paddingTop: "16px",
              // paddingBottom: "10px"
            }}
          />
          <span
            className="mobile"
            style={{
              color: "#f5f5f5",
              fontFamily: "Noto Sans, Arial,sans-serif",
              fontSize: "14px",
              fontWeight: "normal",
              lineHeight: "22px"
            }}
          >
            <form
              action="https://rest.iad-01.braze.com/subscription/user/status"
              method="post"
            >
              <input
                type="radio"
                name="managePrefs"
                checked={
                  responseValue === "Unsubscribe from this preference center"
                }
                onChange={() =>
                  setResponseValue("Unsubscribe from this preference center")
                }
                value="Unsubscribe from this preference center"
                className="checkbox-style"
              />
              <span
                style={{
                  color: "#f5f5f5",
                  fontSize: "16px",
                  lineHeight: "35px"
                }}
              >
                &nbsp;
                <b>
                  Unsubscribe from this preference center ({queryData?.prefName}
                  )
                </b>
              </span>
              <br />
              <input
                type="radio"
                name="managePrefs"
                checked={
                  responseValue === "Temporarily pause all communication"
                }
                onChange={() =>
                  setResponseValue("Temporarily pause all communication")
                }
                value="Temporarily pause all communications"
                className="checkbox-style"
              />
              <span
                style={{
                  color: "#f5f5f5",
                  fontSize: "16px",
                  lineHeight: "35px"
                }}
              >
                &nbsp;
                <b>
                  {" "}
                  Temporarily pause all communications from this preference
                  center
                </b>
              </span>
              <br />
              <input
                type="radio"
                name="managePrefs"
                checked={
                  responseValue === "Unsubscribe from all communications"
                }
                onChange={() =>
                  setResponseValue("Unsubscribe from all communications")
                }
                value="Unsubscribe from all communications"
                className="checkbox-style"
              />
              <span
                style={{
                  color: "#f5f5f5",
                  fontSize: "16px",
                  lineHeight: "35px"
                }}
              >
                &nbsp;
                <b>Unsubscribe from all communications from this business</b>
              </span>
              <br />
              <span
                style={{
                  fontFamily: "Noto Sans, Arial,sans-serif"
                }}
              >
                <input type="submit" value="Update" onClick={handleSubmit} />
              </span>
            </form>
          </span>
          <br />
          <span style={{ color: "#f5f5f5" }}></span>
          <br />
        </div>
      </div>
    </>
  );
}

export default ManagePage;
