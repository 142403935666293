import React from "react";

function Success() {
  return (
    <>
      <div
        className="main-container"
        style={{
          backgroundColor: "#232323",
          color: "white",
          borderTop: "0px",
          paddingTop: "5px",
          paddingBottom: "30px"
        }}
      >
        <div className="content">
          <br />
          <span
            style={{
              color: "#f5f5f5",
              fontFamily: "Noto Sans, Arial,sans-serif",
              fontSize: "28px",
              fontWeight: "500",
              lineHeight: "38px",
              textAlign: "center"
            }}
          >
            Your Email settings has been updated!
          </span>
          <br />
          <br />
          <span
            style={{
              color: "#ffffff",
              fontFamily: "Noto Sans, Arial,sans-serif",
              fontSize: "14px"
            }}
          ></span>
          <br />
          <span style={{ color: "#f5f5f5" }}></span>
          <br />
        </div>
      </div>
    </>
  );
}

export default Success;
