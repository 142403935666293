import { createSlice } from "@reduxjs/toolkit";

const initialUserTokenState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userTokenForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const userTokenSlice = createSlice({
  name: "userToken",
  initialState: initialUserTokenState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUserTokenById
    userTokenFetched: (state, action) => {
      state.actionsLoading = false;
      state.userTokenForEdit = action.payload.userTokenForEdit;
      state.error = null;
    },
    // findUserToken
    userTokensFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createUserToken
    userTokenCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.userToken);
    },
    // updateUserToken
    userTokenUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.userToken.id) {
          return action.payload.userToken;
        }
        return entity;
      });
    },
    // deleteUserToken
    userTokenDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUserToken
    userTokensDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
