import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'

import { DashboardPage } from './pages/DashboardPage'
const LeadPage = lazy(() => import('./modules/Leads/leadPage'))
const LeadViewModule = lazy(() =>
	import('./modules/Leads//pages/leads/lead-view-module/LeadViewModule')
)
const AccountsPage = lazy(() => import('./modules/Accounts/accountsPage'))
const ContactsPage = lazy(() => import('./modules/Contacts/contactsPage'))
const PreferencePage = lazy(() =>
	import('./modules/Preferences/preferencePage')
)
const SettingsPage = lazy(() => import('./modules/Settings/SettingsPage'))
const EmailPage = lazy(() => import('./modules/Email/EmailPage'))
const EmailTemplatePage = lazy(() =>
	import('./modules/Email/EmailTemplatePage')
)
//case
const CategoryPage = lazy(() => import('./case/Category/pages/CategoryPage'))
const SolutionPage = lazy(() => import('./case/Solution/pages/SolutionPage'))
const CasePage = lazy(() => import('./case/Case/pages/CasePage'))
const CaseTypePage = lazy(() =>
	import('./case/Casetype/pages/casetypes/CasetypesPage')
)

export default function BasePage() {
	// useEffect(() => {
	//   console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from='/' to='/dashboard' />
				<ContentRoute path='/dashboard' component={DashboardPage} />
				<Route path='/lead/:id' component={LeadViewModule} />
				<Route path='/leads' component={LeadPage} />
				<Route path='/accounts' component={AccountsPage} />
				<Route path='/contacts' component={ContactsPage} />
				<Route path='/preferences' component={PreferencePage} />
				<Route path='/email/:id' component={EmailTemplatePage} />
				<Route exact path='/email' component={EmailPage} />
				<Route path='/settings' component={SettingsPage} />
				<Route path='/categories' component={CategoryPage} />
				<Route path='/solutions' component={SolutionPage} />
				<Route path='/cases' component={CasePage} />
				<Route path='/case-type' component={CaseTypePage} />
				<Redirect to='error/error-v2' />
			</Switch>
		</Suspense>
	)
}
