/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react"
import { useSelector } from "react-redux"
// import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../_helpers"

export function QuickUser() {
    const history = useHistory()
    const { user } = useSelector((state) => state.auth)

    const logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle")
        if (toggle) {
            toggle.click()
        }
        history.push("/logout")
    }

    return (
        <div id='kt_quick_user' className='offcanvas offcanvas-right offcanvas p-10'>
            <div className='offcanvas-header d-flex align-items-center justify-content-end pb-5'>
                <a href='#' className='btn btn-xs btn-icon btn-light btn-hover-success' id='kt_quick_user_close'>
                    <i className='ki ki-close icon-xs text-muted' />
                </a>
            </div>

            <div className='offcanvas-content pr-5 mr-n5'>
                <div className='d-flex align-items-center mt-5'>
                    <div className='symbol symbol-100 mr-5'>
                        {user.pic !== null && (
                            <div
                                className='symbol-label'
                                style={{
                                    backgroundImage: `url(${user.pic})`,
                                }}
                            />
                        )}

                        <div
                            className='symbol-label'
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl("/media/users/blank.png")})`,
                            }}
                        />
                        {/* <i className="symbol-badge bg-success" /> */}
                    </div>
                    <div className='d-flex flex-column'>
                        <a href='#' className='font-weight-bold font-size-h5 text-dark-75 text-hover-success'>
                            {user.fullname}
                        </a>
                        {/* <div className="text-muted mt-1">{user.occupation}</div> */}
                        {/* <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-success">
                    {user.email}
                  </span>
                </span>
              </a>
            </div> */}
                        {/* <Link to="/logout" className="btn btn-light-success btn-bold">
                Sign Out
              </Link> */}
                        <button className='btn btn-light-success btn-bold' onClick={logoutClick}>
                            Sign out
                        </button>
                    </div>
                </div>

                {/* <div className="separator separator-dashed mt-8 mb-5" /> */}

                {/* <div className="separator separator-dashed my-7"></div> */}
            </div>
        </div>
    )
}
