import { createSlice } from '@reduxjs/toolkit'

const initialCategorysState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	categoryForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const categorysSlice = createSlice({
	name: 'categorys',
	initialState: initialCategorysState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCategoryById
		categoryFetched: (state, action) => {
			state.actionsLoading = false
			state.categoryForEdit = action.payload.categoryForEdit
			state.error = null
		},
		// findCategorys
		categorysFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createCategory
		categoryCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateCategory
		categoryUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.category.id) {
					return action.payload.category
				}
				return entity
			})
		},
		// deleteCategory
		categoryDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteCategorys
		categorysDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// categorysUpdateState
		categoryStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { id, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (id === entity.id) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
