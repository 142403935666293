import React, { useEffect, useState } from "react";
import ApexChart from "./components/ApexChart";
import StatTiles from "./components/StatTiles";
import { fetchData } from "./utils/fetchRecords";
import {
  MixedWidget1,
  AdvanceTablesWidget4
} from "../../_metronic/_partials/widgets";

export function DashboardPage() {
  const [leads, setLeads] = useState({});
  const [converted, setConverted] = useState([]);
  const [open, setOpen] = useState([]);
  const [lost, setLost] = useState([]);
  const [working, setWorking] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [accounts, setAccounts] = useState({});
  const [contacts, setContacts] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .then(result => {
        setIsLoading(false);
        if (result.status === 200) {
          const _converted = result.data.leads.entities.filter(
            ele => ele.status === "Closed"
          );
          const _lost = result.data.leads.entities.filter(
            ele => ele.status === "Lost"
          );
          const _working = result.data.leads.entities.filter(
            ele => ele.status === "Working"
          );
          const _open = result.data.leads.entities.filter(
            ele => ele.status === "Open"
          );
          setWorking(_working);
          setConverted(_converted);
          setLost(_lost);
          setOpen(_open);
          setLeads(result.data.leads);
          setPreferences(result.data.preferences);
          setAccounts(result.data.accounts);
          setContacts(result.data.contacts);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          <div className="row">
            <StatTiles
              color={"success"}
              count={leads.totalCount}
              text={"Leads"}
            />
            <StatTiles
              color={"danger"}
              count={accounts.totalCount}
              text={"Accounts"}
            />
            <StatTiles
              color={"info"}
              count={contacts.totalCount}
              text={"Contacts"}
            />
            <StatTiles
              color={"warning"}
              count={preferences.totalCount}
              text={"Preference Centers"}
            />
          </div>
          <div className="row">
            <div className="col-lg-4">
              <MixedWidget1
                className="card-stretch gutter-b"
                converted={converted.length}
                working={working.length}
                leads={leads.entities}
                open={open.length}
                lost={lost.length}
              />
            </div>
            <div className="col-lg-8">
              <ApexChart leads={leads.entities} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <AdvanceTablesWidget4
                className="card-stretch gutter-b"
                accounts={accounts.entities}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
