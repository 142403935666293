/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'

export function AsideMenuList({ layoutProps }) {
	const location = useLocation()
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url)
			? ' menu-item-active menu-item-open '
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/* Leads */}
				{/*begin::1 Level*/}
				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/leads',
						true
					)}`}
					aria-haspopup='true'
					data-menu-toggle='hover'
				>
					<NavLink className='menu-link menu-toggle' to='/leads'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Shopping/Loader.svg')}
							/>
						</span>
						<span className='menu-text'>LeadGen</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<i className='menu-arrow' />
						<ul className='menu-subnav'>
							<li className='menu-item  menu-item-parent' aria-haspopup='true'>
								<span className='menu-link'>
									<span className='menu-text'>Lead</span>
								</span>
							</li>

							{/* All Leads */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/leads',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/leads'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Leads</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}

							{/* Lead Preferences */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/leads/preference',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink
									className='menu-link menu-toggle'
									to='/leads/preference'
								>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Lead Preferences</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
							{/* Lead Tokens */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/leads/userToken',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink
									className='menu-link menu-toggle'
									to='/leads/userToken'
								>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Lead Tokens</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>
				{/*end::1 Level*/}

				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/accounts',
						true
					)}`}
					aria-haspopup='true'
					data-menu-toggle='hover'
				>
					<NavLink className='menu-link menu-toggle' to='/accounts'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Communication/Shield-user.svg'
								)}
							/>
						</span>
						<span className='menu-text'>Customers</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<i className='menu-arrow' />
						<ul className='menu-subnav'>
							<li className='menu-item  menu-item-parent' aria-haspopup='true'>
								<span className='menu-link'>
									<span className='menu-text'>Customers</span>
								</span>
							</li>

							{/* All Leads */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/accounts',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/accounts'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Account</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}

							{/* Lead Preferences */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/contacts',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/contacts'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Contact</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/preferences', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/preferences'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Design/Anchor-center.svg')}
							/>
						</span>
						<span className='menu-text'>Preference Center</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/email', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/email'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Communication/Mail.svg')}
							/>
						</span>
						<span className='menu-text'>Send Email</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/settings', false)}`}
					aria-haspopup='true'
				>
					<NavLink className='menu-link' to='/settings'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Shopping/Settings.svg')}
							/>
						</span>
						<span className='menu-text'>Settings</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/cases',
						true
					)}`}
					aria-haspopup='true'
					data-menu-toggle='hover'
				>
					<NavLink className='menu-link menu-toggle' to='/cases'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl('/media/svg/icons/Code/Question-circle.svg')}
							/>
						</span>
						<span className='menu-text'>Case</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<i className='menu-arrow' />
						<ul className='menu-subnav'>
							<li className='menu-item  menu-item-parent' aria-haspopup='true'>
								<span className='menu-link'>
									<span className='menu-text'>Case</span>
								</span>
							</li>

							{/* All Leads */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/cases',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/cases'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Cases</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}

							{/* Lead Preferences */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/solutions',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/solutions'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Solutions</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
							{/* Lead Tokens */}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/categories',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/categories'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Categories</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
							{/*begin::2 Level*/}
							<li
								className={`menu-item menu-item-submenu ${getMenuItemActive(
									'/case-type',
									true
								)}`}
								aria-haspopup='true'
								data-menu-toggle='hover'
							>
								<NavLink className='menu-link menu-toggle' to='/case-type'>
									<i className='menu-bullet menu-bullet-dot'>
										<span />
									</i>
									<span className='menu-text'>Case Types</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>
				{/*end::1 Level*/}
			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
