import React, { useEffect } from "react";
import ApexCharts from "apexcharts";

function ApexChart({ leads }) {
  useEffect(() => {
    const categories = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ];
    const element = document.getElementById("chart_2");
    if (!element) {
      return;
    }

    const options = {
      series: generateChartSeriesData(leads, categories),
      chart: {
        toolbar: {
          show: false
        },
        height: 410,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories
      },
      tooltip: {},
      colors: ["#6993FF", "#1BC5BD", "tomato"]
    };

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [leads]);

  return (
    <>
      {" "}
      {/*begin::Card*/}
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Leads by Rating</h3>
          </div>
        </div>
        <div className="card-body">
          {/*begin::Chart*/}
          <div id="chart_2"></div>
          {/*end::Chart*/}
        </div>
      </div>
      {/*end::Card*/}
    </>
  );
}

function generateChartSeriesData(leads, categories) {
  let _series = [
    {
      name: "Hot",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: "Warm",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: "Cold",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ];
  for (let i = 0; i < leads?.length; i++) {
    let _ratingIndex = _series.findIndex(row => row.name === leads[i].rating);
    let _labelIndex = categories.findIndex(
      row =>
        row ===
        new Date(leads[i].createdAt).toLocaleString("default", {
          month: "short"
        })
    );
    if (_labelIndex !== -1) {
      _series[_ratingIndex].data[_labelIndex] =
        _series[_ratingIndex].data[_labelIndex] + 1;
    }
  }
  return _series;
}

export default ApexChart;
