import { createSlice } from '@reduxjs/toolkit'

const initialCasetypesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	casetypeForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const casetypesSlice = createSlice({
	name: 'casetypes',
	initialState: initialCasetypesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCasetypeById
		casetypeFetched: (state, action) => {
			state.actionsLoading = false
			state.casetypeForEdit = action.payload.casetypeForEdit
			state.error = null
		},
		// findCasetypes
		casetypesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createCasetype
		casetypeCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateCasetype
		casetypeUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.casetype.id) {
					entity = { ...entity, ...action.payload.casetype }
					// return action.payload.casetype
				}
				return entity
			})
		},
		// deleteCasetype
		casetypeDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deleteCasetypes
		casetypesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// casetypesUpdateState
		casetypesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
		// casetypeUpdateState
		casetypeStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { status } = action.payload
			state.casetypeForEdit.status = status
		},
	},
})
