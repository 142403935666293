/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function AdvanceTablesWidget4({ className, accounts }) {
  return (
    <>
      {accounts && (
        <>
          {" "}
          <div className={`card card-custom ${className}`}>
            {/* Head */}
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Account Stats
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  Most Recent Accounts
                </span>
              </h3>
              <div className="card-toolbar">
                <Link
                  to="/accounts/new"
                  className="btn btn-info font-weight-bolder font-size-sm mr-3"
                >
                  New Account
                </Link>
                <Link
                  to="/contacts/new"
                  className="btn btn-danger font-weight-bolder font-size-sm"
                >
                  New Contact
                </Link>
              </div>
            </div>
            {/* Body */}
            <div className="card-body pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-7" style={{ minWidth: "200px" }}>
                          <span className="text-dark-75">Last Name</span>
                        </th>
                        <th style={{ minWidth: "100px" }}>email</th>
                        <th style={{ minWidth: "100px" }}>source</th>
                        <th style={{ minWidth: "130px" }}>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map(account => (
                        <tr key={account.id}>
                          <td className="pl-0">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 symbol-light mr-4">
                                <span className="symbol-label">
                                  <span className="svg-icon h-75 align-self-end">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/avatars/001-boy.svg"
                                      )}
                                    />
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                                  {account.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {account.email}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {account.source}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`label label-lg label-light-success label-inline`}
                            >
                              {account.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
