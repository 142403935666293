import { createSlice } from "@reduxjs/toolkit";

const initialSettingState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: undefined,
  settingsForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSettingById
    settingFetched: (state, action) => {
      state.actionsLoading = false;
      state.settingsForEdit = action.payload.settingsForEdit;
      state.error = null;
    },
    // findSetting
    settingsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    }
    // updateSetting
    // settingsUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map(entity => {
    //     if (entity.id === action.payload.setting.id) {
    //       return action.payload.setting;
    //     }
    //     return entity;
    //   });
    // }
  }
});
